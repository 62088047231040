import React from 'react';
import { ImageType } from 'types/CommonTypes';
import { Image, StyledSectionWrapper } from './PartnersSection.styled';
import { Link } from 'gatsby';

export interface PartnersSectionProps {
    logos: {
        partnerLink: string;
        partnerLinkType: 'internal' | 'external';
        partnerLogo: ImageType;
    }[];
    pathname: string;
}

export const PartnersSection = ({ pathname, logos }: PartnersSectionProps) => {
    return (
        <StyledSectionWrapper>
            {logos?.map((logo, index) => {
                return logo?.partnerLinkType === 'internal' ? (
                    <Link key={index} to={`${pathname}/${logo?.partnerLink}`}>
                        <Image
                            alt={logo?.partnerLogo?.altText}
                            src={logo?.partnerLogo?.sourceUrl}
                        />
                    </Link>
                ) : (
                    <a target="_blank" href={logo?.partnerLink}>
                        <Image
                            alt={logo?.partnerLogo?.altText}
                            src={logo?.partnerLogo?.sourceUrl}
                        />
                    </a>
                );
            })}
        </StyledSectionWrapper>
    );
};
